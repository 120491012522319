import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { getItemsCountFromDB, selectCartItemsCount } from 'srs.sharedcomponents/lib/esm/redux/slices/cartSlice'
import { selectWishListMode } from 'srs.sharedcomponents/lib/esm/redux/slices/wishlistSlice'
import { LinkType, wishListDbInteractions } from 'srs.sharedcomponents/lib/esm/utils/enums'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronLeft, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import TopNavigationComponent from 'srs.sharedcomponents/lib/esm/components/header/srs.topNavigation.component'
import { useTranslation } from 'react-i18next'
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import { dealershipName, fullName } from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice'
import { setLastVisitedCatID, setLastVisitedSearch } from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice'
import { removeSpecialCharacter } from 'srs.sharedcomponents/lib/esm/utils/validators'
import { mainMenuItems } from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import DOMPurify from 'dompurify'
interface IHeaderComponentProps {
  toggleLeftSideMobileMenu?: () => void
}

const HeaderComponent = (props: IHeaderComponentProps) => {
  const welcome = useAppSelector((state) => state?.welcome?.welcome)
  const { t } = useTranslation()
  const history = useHistory()
  const [searchString, setSearchString] = useState('')
  const [productSearchError, setProductsearchError] = useState(false)
  useEffect(() => {
    setProductsearchError(false)
  }, [history.length])
  const onChange = (event: any) => {
    setSearchString(removeSpecialCharacter(event.target.value))
  }
  const onSubmit = (event: any) => {
    if (event.key === 'Enter' || event.type === 'click') {
      event.preventDefault()
      if (errMesage()) {
        setProductsearchError(true)
        return
      }
      if (productSearchError) setProductsearchError(false)
      dispatch(setLastVisitedCatID(null))
      dispatch(setLastVisitedSearch(searchString))
      history.push(`ProductListCatalog?search=${searchString}`)
      setSearchString('')
    }
  }
  function errMesage() {
    if (searchString.length === 0) {
      return t(`valSearch`, {
        ns: namespace.coreSrsPrimary,
      })
    }
    if (searchString.length < 3 || searchString.length > 100) {
      return t(`Search_MinSearchError`, {
        ns: namespace.coreSrsPrimary,
      })
    }
  }
  const dispatch = useAppDispatch()
  const [cartCount, setCartCount] = useState<string>('0')
  const cartItemsCount: number = useAppSelector(selectCartItemsCount)
  const wishListMode: wishListDbInteractions = useAppSelector(selectWishListMode)
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const [isLeftMenuVisible, setLeftMenuVisible] = useState(false)
  const [getComponentName, setComponentName] = useState('')
  const fullNameDb = useAppSelector(fullName)
  const dealershipNameDb = useAppSelector(dealershipName)
  const menuItems = useAppSelector(mainMenuItems)

  /**
   * Toggle mobile menu
   */
  const handleMobileMenu = () => {
    setIsMenuExpanded(!isMenuExpanded)
  }

  useEffect(() => {
    //after moveFromWishListToCart
    if (wishListMode === wishListDbInteractions.moveToCartFulfilled) dispatch(getItemsCountFromDB())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishListMode])

  useEffect(() => {
    getComponentNameToVisualizeSideMenu()
    // eslint-disable-line react-hooks/exhaustive-deps
  })

  const getComponentNameToVisualizeSideMenu = () => {
    setComponentName(history.location.pathname)
    if (getComponentName === '' || getComponentName === '/' || getComponentName === '/ProductListCatalog') {
      setLeftMenuVisible(true)
    } else {
      setLeftMenuVisible(false)
    }
  }
  useEffect(() => {
    if (cartItemsCount !== null) {
      cartItemsCount > 99 ? setCartCount('99+') : setCartCount(cartItemsCount.toString())
    }
  }, [cartItemsCount])
  return (
    <Container>
      <Col className='header'>
        <div className='header__row'>
          <Col lg={12} md={12}>
            <Row>
              <Col className=''>
                <div className={'header__top-nav-desktop'}>
                  <TopNavigationComponent />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12}>
                <Link to='/' className='position-relative'>
                  <span className='header-text' dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t(`HeaderTitle`, {
                      ns: namespace.coreSrsPrimary,
                    }))
                  }}></span>


                  <img id='headerLogoImg' className='img-fluid logo' src={process.env.REACT_APP_LOGOURL} alt='subaru logo' />
                </Link>
              </Col>

            </Row>
          </Col>
          <Col lg={12} md={12} xs={12} className='ml-auto text-md-right'>
            <Row>
              <Col md={6} className='header__text-right'>
              </Col>
              <Col md={6} className='header__text-right'>
                <form className='form-group m-0 text-right'>
                  <div className='input-group mb-2 mr-sm-2 hideFocus'>
                    <input
                      type='text'
                      className='form-control form-control-lg border-rounded-10'
                      id='search'
                      name='search'
                      value={searchString}
                      onChange={onChange}
                      onKeyDown={onSubmit}
                      placeholder='Search Products'
                    />
                    <div className='invalid-feedback'>Please search here</div>
                    <div className='header__input-group-append'>
                      <button
                        className='input-group-text btn bg-white border-rounded-right-10 border-left-0'
                        id='btnsubmit'
                        type='button'
                        onClick={onSubmit}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                  {productSearchError && (
                    <label id='ProductsearchError' className={`'d-inline-block text-danger`}>
                      {errMesage()}
                    </label>
                  )}
                </form>
              </Col>
              <Col xl={12} lg={12} md={12} className='text-end text-md-start'>
                <span className='header__welcome-text d-block' id='welcomeText'>
                  {t(`txtWelcomeMessage`, {
                    ns: namespace.coreSrsPrimary,
                  })}{' '}
                  {fullNameDb}
                  {dealershipNameDb && ', ' + dealershipNameDb}
                  <span className='ml-1' id='loginname'></span>
                </span>
              </Col>
            </Row>
          </Col>
        </div>
        <Col className='header__row'>
          <Col className='header__links main-navigation'>
            <nav className='navbar navbar-expand-lg w-100 main-menu p-0' id='main-menu'>
              {!isLeftMenuVisible && (
                <button className='menu-button btn btn-dark d-lg-none ml-2' id='sidebarCollapse' onClick={props.toggleLeftSideMobileMenu}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              )}

              <button id='mobileMenuButton' onClick={handleMobileMenu} className='menu-button btn btn-dark d-lg-none ml-auto mr-2'>
                {!isMenuExpanded ? (
                  <FontAwesomeIcon icon={faBars} className='main-menu-icon' />
                ) : (
                  <FontAwesomeIcon icon={faTimes} className='main-menu-icon' />
                )}
              </button>
              <Col className={`${isMenuExpanded ? 'expand navbar-collapse' : 'collapse navbar-collapse'} justify-content-between `}>
                <ul className='nav navbar-nav' onClick={handleMobileMenu}>
                  {menuItems?.map((item, i) => {
                    if (item.linkType === LinkType.ReactMarkExt) {
                      if (item.name === 'Home')
                        return (
                          <Link key={i} className='header__button' id={item.name} to='/'>
                            {item.name}
                          </Link>
                        )
                      else {
                        return (
                          <Link
                            key={i}
                            className='header__button'
                            id={item.name}
                            to={item.name.replace(/\s/g, '').toLowerCase()}
                            target={item.placementType}
                          >
                            {item.name}
                          </Link>
                        )
                      }
                    } else if (item.linkType === LinkType.ReactInternal) {
                      return (
                        <Link key={i} className='header__button' id={item.name} to={item.url} target={item.placementType}>
                          {item.name}
                        </Link>
                      )
                    } else {
                      return (
                        <a id={item.name} key={i} className='header__button' href={item.url} target={item.placementType}>
                          {item.name}
                        </a>
                      )
                    }
                  })}
                </ul>
                <Col md={2} className='header__right-menu row justify-content-end '>
                  <Col lg={1} md={12}>
                    <Link className='position-relative desktop-cart' to='/cart' id='headerCartIcon'>
                      <img src='/images/CartWhite.png' alt='' className='cart-icon' />
                      <span id='lblCartItemsCount' className='BlinkCartItemCount BlinkCartItemCountHeader circle'>
                        <span id='CartItemCount'> {` ${cartCount} `} </span>
                      </span>
                    </Link>
                    <div onClick={handleMobileMenu}>
                      <Link className='header__button mobile position-relative' to='/cart' id='headerMobileCartIcon'>
                        <img src='/images/CartBlack.png' alt='' className='cart-icon' />
                        <span id='lblCartItemsCount' className='BlinkCartItemCount BlinkCartItemCountHeader circle'>
                          <span id='CartItemCount'> {` ${cartCount} `} </span>
                        </span>
                      </Link>
                    </div>
                    <div className={'header__top-nav-mobile'}>
                      <TopNavigationComponent handleMobileMenu={handleMobileMenu} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <a className='te' href={welcome?.helpLink} target='_blank' id='headerHelpIcon' rel='noreferrer'>
                      <span className='srs-icon-help-circle text-white h3'></span>
                    </a>
                  </Col>
                </Col>
              </Col>
            </nav>
          </Col>
        </Col>
      </Col>
    </Container>
  )
}
export default HeaderComponent
