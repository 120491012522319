/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';import HeaderComponent from './srs.header.component';
import FooterComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.footer.component';
import WelcomeComponent from './srs.welcome.component';
import AccordionComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.accordion.component';
import ProductListComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.productList.component';
import PasswordRecoveryComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.passwordRecovery.component';
import LoginComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.login.component';
import ContactUsComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.contactus.component';
import RegistrationComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.registration.component';
import QuickOrderComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.quickOrder.component';
import OrderHistoryDetailsComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.orderHistoryDetails.component';
import ThankyouComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.thankyou.component';
import PrefferencesComponent from 'srs.sharedcomponents/lib/esm/components/prefferences/srs.prefferences.component';
import { Switch, Route, useLocation } from 'react-router-dom';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import LoadingIndicator from 'srs.sharedcomponents/lib/esm/components/LoadingIndicator';
import ItemDetailComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.itemDetail.component';
import {
    useAppDispatch,
    useAppSelector,
} from 'srs.sharedcomponents/lib/esm/redux/hooks';
import {
    getWelcome,
    setWelcome,
    mainMenuItems,
    dbCountry,
    dbLanguage,
    isCurrentCountryIndependent,
    setLinksLocale,
} from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice';
import SrsWishlistComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.wishlist.component';
import SrsCartComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.cart.component';
import swal from 'sweetalert2';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import { IWelcome } from 'srs.sharedcomponents/lib/esm/models/srs.welcome.model';
import { useStore } from 'react-redux';
import {
    clearReduxWishList,
    setDefaultWishListState,
} from 'srs.sharedcomponents/lib/esm/redux/slices/wishlistSlice';
import {
    clearState as clearUser,
    setCountryAndLanguage,
} from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice';
import {
    clearAll as clearReduxCart,
    setDefaultCartState,
} from 'srs.sharedcomponents/lib/esm/redux/slices/cartSlice';
import { clearState as clearCheckout } from 'srs.sharedcomponents/lib/esm/redux/slices/checkoutSlice';
import { initState as clearQuickOrder } from 'srs.sharedcomponents/lib/esm/redux/slices/quickOrderSlice';
import CheckoutComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.checkout.component';
import OrderHistoryComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.orderHistory.component';
import PrefferencesChangePasswordComponent from 'srs.sharedcomponents/lib/esm/components/prefferences/srs.prefferencesChangePassword.component';
import CorporateInfoComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.corporateInfo.component';
import WebAccessibilityComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.webAccessibility.component';
import ConsentModalComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.consentModal.component';
import {
    httpRespStatusCode,
    LinkType,
} from 'srs.sharedcomponents/lib/esm/utils/enums';
import StaticPageRender from 'srs.sharedcomponents/lib/esm/components/srs.staticPageRender.component';
import { useTranslation } from 'react-i18next';
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n';
import ReactGA from 'react-ga';
import {
    setErrorText,
    stringToBoolean,
} from 'srs.sharedcomponents/lib/esm/utils/helpers';
import OpenSourcesComponent from 'srs.sharedcomponents/lib/esm/components/openSources.component';
import PageTitle from 'srs.sharedcomponents/lib/esm/shared-components/srs.pageTitle.component';
import ParameterSelector from 'srs.sharedcomponents/lib/esm/components/webPartEditor/parameterSelector';
import WebPartEditorComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.webPartEditor.component';
import EnhancedRegistrationActivation from 'srs.sharedcomponents/lib/esm/shared-components/srs.enhancedRegistrationActivation.component';
import NotFoundPageComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.notFoundPage.component';
import './css/main.css';
import { IEmbededPage } from 'srs.sharedcomponents/lib/esm/models/srs.embededPage.model';
import { locales } from 'srs.sharedcomponents/lib/esm/utils/dictionaries';
import { RootState } from 'srs.sharedcomponents/lib/esm/redux/store';
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web'
import { env } from 'srs.sharedcomponents/lib/esm/api/env'
import coreSrsPrimaryOverride from './i18n/coreSrsPrimaryOverride.json'
import { globalStyles } from "srs.sharedcomponents/lib/esm/styles/global";

interface IAppWrapper {
    appInsights: ApplicationInsights
}

globalStyles();

function SharedApp({ appInsights }: IAppWrapper) {
    const { i18n, t } = useTranslation();
    Object.entries(coreSrsPrimaryOverride).forEach(([locale, entity]) =>{
		Object.entries(entity).forEach(([key, value]) => {
			i18n.addResource(locale, namespace.coreSrsPrimary, key, value)
		})
	})
    const cookeisPages: IEmbededPage[] = [
        {
            name: t(`Cookie_Policy_header`, { ns: namespace.srsCookies }),
            src: t(`Cookie_Policy`, { ns: namespace.srsCookies }),
        },
        {
            name: t(`Third_party_cookies_header`, { ns: namespace.srsCookies }),
            src: t(`Third_party_cookies`, { ns: namespace.srsCookies }),
        },
        {
            name: t(`Cookies_used_by_this_websites_header`, {
                ns: namespace.srsCookies,
            }),
            src: t(`Cookies_used_by_this_websites`, {
                ns: namespace.srsCookies,
            }),
        },
    ];
    const welcome = useAppSelector(
        (state: RootState) => state?.welcome?.welcome
    );
    const store = useStore();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const [isLeftSideMenuExpand, setIsLeftSideMenuExpand] = useState(false);
    const menuItems = useAppSelector(mainMenuItems);
    const userLang = useAppSelector(
        (state: RootState) => state.user.user?.languageId
    );
    const language = useAppSelector(dbLanguage);
    const country = useAppSelector(dbCountry);
    const isCurrCountryIndependent = useAppSelector(
        isCurrentCountryIndependent
    );
    const checkForIndependance = stringToBoolean(
        process.env.REACT_APP_CHECK_FOR_COUNTRY_INDEPENDENCE
    );
    
    useEffect(() => {
        const pageInfo: IPageViewTelemetry = {
            name: location?.pathname,
            uri: window?.location.href,
            isLoggedIn: store.getState().user?.user?.token ? true : false
        }
        if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
            appInsights.trackPageView(pageInfo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        setGA();
        saveLanguageToLocalStorage();
        if (welcome?.anonymousId === '') {
            dispatch(getWelcome());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [welcome?.anonymousId]);

    useEffect(() => {
        if (!userLang) {
            dispatch(
                setCountryAndLanguage({
                    country: Number(country),
                    language: Number(language),
                })
            );
            dispatch(setLinksLocale(Number(language)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    useEffect(() => {
        const handleRedirect = () => {
            if (!location.pathname.includes('/PasswordRecovery')) {
                history.push('/mypreferences');
            }
        };
        if (!store.getState().user?.user?.token && !welcome?.anonymousId) {
            handleRedirect();
        };
    }, []);

    const setUnauthorizedState = () => {
        if (store.getState().user?.user?.token) {
            history.push('/');

            dispatch(clearUser());
            dispatch(clearReduxWishList());
            dispatch(clearReduxCart());
            dispatch(clearCheckout());
            dispatch(clearQuickOrder());

            swal.fire(
                'Session Expired!',
                'Your session has expired. Would you like to be redirected to the login page?',
                'warning'
            ).then(function () {
                history.push('/login');
            });
        } else {
            dispatch(setWelcome({ anonymousId: '' } as IWelcome));
            dispatch(getWelcome());
            dispatch(setDefaultCartState());
            dispatch(setDefaultWishListState());
        }
    };

    const setValidMessage = (intercRespErr: AxiosError<any>) => {
        /*intercRespErr?.response?.data.title is automatically returned validation error from .net core 
      (not any specified validation messages returned from the ML)
    */
        if (
            intercRespErr?.response?.data.title !== undefined ||
            intercRespErr?.response?.data?.message?.includes('No order found')
        )
            intercRespErr.response.data = t('SRS_ErrorPage_BadRequest', {
                ns: namespace.coreSrsPrimary,
            });

        return intercRespErr;
    };

    axios.interceptors.response.use(
        function (interceptedResponse: AxiosResponse) {
            if (interceptedResponse !== undefined) {
                return interceptedResponse;
            }
        },
        function (intercRespErr: any) {
            if (intercRespErr.response) {
                const { status } = intercRespErr.response;
                const { badRequest, internalServerError, unauthorized } =
                    httpRespStatusCode;
                const msg: string = setErrorText(intercRespErr, status);

                if (status >= badRequest && status < internalServerError) {
                    //Client error responses
                    if (unauthorized === status) {
                        setUnauthorizedState();
                    } else if (status === badRequest) {
                        return Promise.reject({
                            ...setValidMessage(intercRespErr),
                            message: '',
                        });
                    } else {
                        return Promise.reject({
                            ...intercRespErr,
                            message: '',
                        });
                    }
                } else if (status >= 500) {
                    //Server error responses
                    !intercRespErr.response?.data && swal.fire('', msg);
                    return Promise.reject({ ...intercRespErr, message: '' });
                }
            } else {
                //Server is not responding
                const message = t(
                    'SRS_ErrorPage_GeneralMessage',
                    namespace.coreSrsPrimary
                )
                    .replace('<a', '<a style="color:blue" ')
                    .replace('{0}', ' /ContactUs '); // TO DO text: message.replace('{0}', 'mailto:name@email.com'),
                swal.fire('', message);

                return Promise.reject({ ...intercRespErr, message: '' });
            }
        }
    );

    /**
     * Open and close left side mobile menu
     */

    const toggleLeftSideMobileMenu = () => {
        setIsLeftSideMenuExpand(isLeftMenuExpand => !isLeftMenuExpand);
    };

    /**
     * Reset Scroll position on change of Routes
     */
    history.listen(() => {
        window.scrollTo(0, 0);
    });

    const setGA = () => {
        ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`, {
            debug: false,
        });
    };

    const saveLanguageToLocalStorage = () => {
        const lang = locales.get(Number(store.getState().user.user.languageId));
        if (lang) {
            i18n.changeLanguage(lang);
        }
    };

    if (welcome?.anonymousId === '') return <LoadingIndicator />;
    return (
        <div className='App'>
            {location.pathname !== '/ssoprocessing' && (
                <>
                    <ParameterSelector />
                    <HeaderComponent
                        toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
                    />
                    <ConsentModalComponent />
                </>
            )}
            <Switch>
                <Route path='/' exact>
                    <PageTitle
                        title={t(`headerHomeLabel`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <WelcomeComponent
                            isLeftSideMobileMenuExpand={isLeftSideMenuExpand}
                            toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
                        />
                    </PageTitle>
                </Route>
                <Route exact path='/ProductListCatalog'>
                    <PageTitle
                        title={t(`txtProductList`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <ProductListComponent
                            isLeftSideMobileMenuExpand={isLeftSideMenuExpand}
                            toggleLeftSideMobileMenu={toggleLeftSideMobileMenu}
                        />
                    </PageTitle>
                </Route>
                <Route exact path='/getcorporateinformation'>
                    <PageTitle
                        title={t(`txtCorporateInformation`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <CorporateInfoComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/cookies'>
                    <PageTitle
                        title={t(`txtCookiePolicy`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <AccordionComponent
                            pages={cookeisPages}
                            title={t(`Use_Of_Cookies_Label`, {
                                ns: namespace.coreSrsPrimary,
                            })}
                        />
                    </PageTitle>
                </Route>

                <Route exact path='/warranty'>
                    <PageTitle
                        title={t(`Footer_Warranty`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <AccordionComponent
                            pages={[
                                {
                                    name: t(`Footer_Warranty`, {
                                        ns: namespace.coreSrsPrimary,
                                    }),
                                    url: welcome?.warranty,
                                },
                            ]}
                        />
                    </PageTitle>
                </Route>
                <Route exact path='/opensources'>
                    <PageTitle
                        title={t(`txtOpenSources`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <OpenSourcesComponent />
                    </PageTitle>
                </Route>

                <Route exact path='/webaccessibility'>
                    <PageTitle
                        title={t(`txtWebAccessibility`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <WebAccessibilityComponent />
                    </PageTitle>
                </Route>
                {menuItems?.map((item, i) => {
                    if (item.linkType === LinkType.ReactMarkExt) {
                        return (
                            <Route
                                key={i}
                                path={
                                    '/' +
                                    item.name.replace(/\s/g, '').toLowerCase()
                                }
                            >
                                <StaticPageRender
                                    {...{
                                        name: item.name,
                                        url: item.url,
                                    }}
                                />
                            </Route>
                        );
                    }
                    return null
                })}
                <Route exact path='/ItemDetail'>
                    <PageTitle
                        title={t(`txtProductDetails`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <ItemDetailComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/wishlist'>
                    <PageTitle
                        title={t(`OtherWishListTitlestr`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <SrsWishlistComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/cart'>
                    <PageTitle
                        title={t(`ShoppingCartstr`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <SrsCartComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/login'>
                    <PageTitle
                        title={t(`txtLogin`, { ns: namespace.coreSrsPrimary })}
                    >
                        <LoginComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/PasswordRecovery'>
                    <PageTitle
                        title={t(`RecoverPasswordTitle`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <PasswordRecoveryComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/en-US/pagesSecurity/PasswordRecovery'>
                    <PasswordRecoveryComponent />
                </Route>
                <Route exact path='/en-US/Pages/PasswordRecovery.aspx'>
                    <PasswordRecoveryComponent />
                </Route>
                <Route exact path='/en-US/Security/PasswordRecovery'>
                    <PasswordRecoveryComponent />
                </Route>
                {(!checkForIndependance || isCurrCountryIndependent) && (
                    <Route exact path='/registration'>
                        <PageTitle
                            title={t(`RegisterLinkText`, {
                                ns: namespace.coreSrsPrimary,
                            })}
                        >
                            <RegistrationComponent />
                        </PageTitle>
                    </Route>
                )}
                <Route exact path='/contactus'>
                    <PageTitle
                        title={t(`Footer_ContactUsLinkText`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <ContactUsComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/quickOrder'>
                    <PageTitle
                        title={t(`lblQuickOrderHeader`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <QuickOrderComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/checkout'>
                    <PageTitle
                        title={t(`btnCheckOutToolTip`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <CheckoutComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/orderHistoryDetails'>
                    <PageTitle
                        title={t(`txtOrderHistory`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <OrderHistoryDetailsComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/thankyou'>
                    <PageTitle
                        title={t(`CheckoutV2_ThankYou`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <ThankyouComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/mypreferences'>
                    <PageTitle
                        title={t(`txtPreference`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <PrefferencesComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/changePassword'>
                    <PageTitle
                        title={t(`btnChangePasswordText`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <PrefferencesChangePasswordComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/orderHistory'>
                    <PageTitle
                        title={t(`txtOrderHistory`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <OrderHistoryComponent />
                    </PageTitle>
                </Route>
                <Route exact path='/webPartEditor'>
                    <WebPartEditorComponent />
                </Route>
                <Route exact path='/userActivation'>
                    <EnhancedRegistrationActivation />
                </Route>
                {/* THIS ROUTE SHOULD BE LAST */}
                <Route>
                    <PageTitle
                        title={t(`Error404Text`, {
                            ns: namespace.coreSrsPrimary,
                        })}
                    >
                        <NotFoundPageComponent />
                    </PageTitle>
                </Route>
            </Switch>
            <FooterComponent />
        </div>
    );
}

export default SharedApp;
