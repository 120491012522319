import { Colors } from 'srs.sharedcomponents/lib/esm/styles/config';

export const customTheme = {
    colors: {
        navTextButtonColor: Colors.white,
        formLabel: Colors.black,
        menuButton: Colors.white,
        menuButtonMobile: Colors.black,
    },
};
