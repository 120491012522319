import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { store } from 'srs.sharedcomponents/lib/esm/redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { injectStore } from 'srs.sharedcomponents/lib/esm/api/agent'
import Spinner from 'srs.sharedcomponents/lib/esm/components/Spinner'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { env } from 'srs.sharedcomponents/lib/esm/api/env'
import './css/main.css'
import ThemeWrapper from "srs.sharedcomponents/lib/esm/components/molecules/ThemeWrapper/ThemeWrapper"
import { ThemeProvider } from "srs.sharedcomponents/lib/esm/components/context/themeContext"
import { customTheme } from "./styles/theme"


injectStore(store)
const persistor = persistStore(store)

const initApplicationInsights = () => {
  return new ApplicationInsights({
    config: {
      connectionString: env.APPLICATIONINSIGHTS_CONNECTION_STRING,
      /* ...Other Configuration Options... */
    },
  })
}

const appInsights = initApplicationInsights()

if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
  appInsights.loadAppInsights()
  appInsights.trackPageView() // Manually call trackPageView to establish the current user/session/pageview
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <ThemeWrapper theme={customTheme}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            <Router>
              <App appInsights={appInsights} />
            </Router>
          </PersistGate>
        </ThemeWrapper>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
